import React, { useEffect, useRef, useState } from "react";
import { NotificationAPI } from "../../utils/api/NotificationAPI";

import "./scss/NotificationBar.scss";
import WarningCloseIcon from "../Icons/WarningCloseIcon";

interface INotification {
  category: string;
  text: string;
}

const NotificationBar = () => {
  const notificationRef = useRef<HTMLDivElement | null>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [text, setText] = useState("");
  const [isVisible, setIsVisible] = useState(true);

  const getData = async () => {
    const res = await NotificationAPI.getNotifications();
    if (!res.error) {
      res.data.forEach((noti: INotification) => {
        if (noti.category === "global") setText(noti.text);
      });
    }
  };

  const handleCloseNotification = () => {
    const hideDuration = 3600000; // 1 hour in milliseconds
    const hideUntil = new Date().getTime() + hideDuration;
    localStorage.setItem("hideUntil", hideUntil.toString());
    setIsVisible(false);
  };

  const checkHiddenState = () => {
    const hideUntil = localStorage.getItem("hideUntil");
    if (hideUntil) {
      const currentTime = new Date().getTime();
      if (currentTime < parseInt(hideUntil)) setIsVisible(false);
      else localStorage.removeItem("hideUntil"); // Time has passed, clear localStorage
    }
  };

  useEffect(() => {
    const checkOverflow = () => {
      if (notificationRef.current) {
        const notification = notificationRef.current;
        setIsOverflowing(notification.scrollWidth > notification.clientWidth);
      }
    };

    checkOverflow(); // Initial check
    window.addEventListener("resize", checkOverflow); // Check on resize

    return () => {
      window.removeEventListener("resize", checkOverflow); // Cleanup on unmount
    };
  }, [text]); // Run effect whenever the message changes

  useEffect(() => {
    checkHiddenState();
    getData();
  }, []);

  return text && isVisible ? (
    <div
      className={`notification-bar ${isOverflowing ? "overflow" : ""}`}
      ref={notificationRef}
    >
      <span>{text}</span>
      <button
        className="button-close"
        onClick={() => handleCloseNotification()}
      >
        <WarningCloseIcon />
      </button>
    </div>
  ) : null;
};

export default NotificationBar;
