import React from "react";

const SolanaIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 20"
      fill="none"
      className="chain-icon fill"
    >
      <g clipPath="url(#clip0_10139_91143)">
        <path
          d="M3.89768 14.9515C4.04251 14.8067 4.24166 14.7222 4.45287 14.7222H23.607C23.957 14.7222 24.132 15.1446 23.8846 15.392L20.1008 19.1758C19.956 19.3206 19.7569 19.4051 19.5457 19.4051H0.391519C0.0415063 19.4051 -0.1335 18.9827 0.113923 18.7352L3.89768 14.9515Z"
          fill="#79DEFF"
        />
        <path
          d="M3.89768 0.824289C4.04855 0.679456 4.24769 0.594971 4.45287 0.594971H23.607C23.957 0.594971 24.132 1.0174 23.8846 1.26482L20.1008 5.04858C19.956 5.19341 19.7569 5.2779 19.5457 5.2779H0.391519C0.0415063 5.2779 -0.1335 4.85547 0.113923 4.60805L3.89768 0.824289Z"
          fill="#79DEFF"
        />
        <path
          d="M20.1008 7.8426C19.956 7.69777 19.7569 7.61328 19.5457 7.61328H0.391519C0.0415063 7.61328 -0.1335 8.03571 0.113923 8.28313L3.89768 12.0669C4.04251 12.2117 4.24166 12.2962 4.45287 12.2962H23.607C23.957 12.2962 24.132 11.8738 23.8846 11.6264L20.1008 7.8426Z"
          fill="#79DEFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_10139_91143">
          <rect
            width="24"
            height="18.8102"
            fill="white"
            transform="translate(0 0.594971)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SolanaIcon;
