import { BaseAPI } from "./BaseAPI";

export class NotificationAPI {
  static getNotifications = async () => {
    return BaseAPI.callAPI({
      url: "/notifications/",
      needToken: false,
    });
  };
}
