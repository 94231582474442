import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  IStatisticsDexVolumeHistory,
  IStatisticsDexVolumeQuery,
  IStatisticsMarketCapHistory,
  IStatisticsMarketCapQuery,
  IStatisticsNumTxnsHistory,
} from "../../@types/redux";
import { StatisticsAPI } from "../../utils/api/StatisticsAPI";

const initialState = {
  marketCapHistory: {
    loading: true,
    market_cap: 0,
    changed_percent: 0,
    history: [],
  },
  dexVolume: {
    loading: true,
    value: 0,
    changed_rate: 0,
    history: [],
  },
};

const statisticsSlice = createSlice({
  name: "dexStatistics",
  initialState,
  reducers: {
    getMarketCapHistory: (
      state,
      _: PayloadAction<IStatisticsMarketCapQuery>
    ) => {
      state.marketCapHistory.loading = true;
    },

    setMarketCapHistory: (
      state: any,
      action: PayloadAction<IStatisticsMarketCapHistory>
    ) => {
      state.marketCapHistory.loading = false;
      state.marketCapHistory.market_cap = action.payload.market_cap;
      state.marketCapHistory.changed_percent = action.payload.changed_percent;
      state.marketCapHistory.history = action.payload.history;
    },

    getDexVolume: (state, _: PayloadAction<IStatisticsDexVolumeQuery>) => {
      state.dexVolume.loading = true;
    },

    setDexVolume: (
      state: any,
      action: PayloadAction<IStatisticsDexVolumeHistory>
    ) => {
      state.dexVolume.loading = false;
      state.dexVolume.value = action.payload.value;
      state.dexVolume.changed_rate = action.payload.changed_rate;
      state.dexVolume.history = action.payload.history;
    },

    setNumTxns: (
      state: any,
      action: PayloadAction<IStatisticsNumTxnsHistory>
    ) => {
      state.numTxns.loading = false;
      state.numTxns.value = action.payload.value;
      state.numTxns.changed_rate = action.payload.changed_rate;
      state.numTxns.history = action.payload.history;
    },
  },
});

const statisticsActions = statisticsSlice.actions;
const statisticsReducer = statisticsSlice.reducer;

export { statisticsActions, statisticsReducer };

// Sagas

function* getMarketCapHistory(action: any): any {
  const res = yield call(StatisticsAPI.getMarketCapHistory, {
    ...action.payload,
  });
  if (!res.error) {
    yield put(statisticsActions.setMarketCapHistory({ ...res.data }));
  } else {
    yield put(
      statisticsActions.setMarketCapHistory({
        market_cap: 0,
        changed_percent: 0,
        history: [],
      })
    );
  }
}

function* getDexVolume(action: any): any {
  const res = yield call(StatisticsAPI.getDexVolume, { ...action.payload });
  if (!res.error) {
    yield put(statisticsActions.setDexVolume({ ...res.data }));
  } else {
    yield put(
      statisticsActions.setDexVolume({
        value: 0,
        changed_rate: 0,
        history: [],
      })
    );
  }
}

export function* statisticsSaga() {
  yield all([
    takeLatest(statisticsActions.getMarketCapHistory, getMarketCapHistory),
    takeLatest(statisticsActions.getDexVolume, getDexVolume),
  ]);
}
