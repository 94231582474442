import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  ITotalAccumulations,
  ITotalAccumulationsQuery,
} from "../../@types/redux";
import { AccumulationsAPI } from "../../utils/api/AccumulationsAPI";

export const totalAccumulationsDefaultQuery = {
  chain: "",
  page_size: 20,
  page: 1,
  ordering: "-volume_1day",
};

const initialState = {
  isRefreshing: false,
  loading: false,
  total_count: 0,
  total_page: 0,
  results: [],
  query: { ...totalAccumulationsDefaultQuery },
};

const totalAccumulationsSlice = createSlice({
  name: "totalAccumulations",
  initialState,
  reducers: {
    getList: (state, _: PayloadAction<ITotalAccumulationsQuery>) => {
      if (!state.isRefreshing) {
        state.loading = true;
        state.results = [];
      }
    },

    setList: (state, action: PayloadAction<ITotalAccumulations>) => {
      state.isRefreshing = false;
      state.loading = false;
      state.total_count = action.payload.total_count;
      state.total_page = action.payload.total_page;
      state.results = action.payload.results;
    },

    setQuery: (state, action: PayloadAction<ITotalAccumulationsQuery>) => {
      state.query.page_size = action.payload.page_size;
      state.query.page = action.payload.page;
      state.query.ordering = action.payload.ordering;
      state.query.chain = action.payload.chain;
    },

    resetFilters: (state, _) => {
      state.isRefreshing = true;
      state.query = {
        ...totalAccumulationsDefaultQuery,
      };
    },
  },
});

const totalAccumulationsAction = totalAccumulationsSlice.actions;
const totalAccumulationsReducer = totalAccumulationsSlice.reducer;

export { totalAccumulationsAction, totalAccumulationsReducer };

// Sagas

function* getTotalAccumulations(
  action: PayloadAction<ITotalAccumulationsQuery>
): any {
  const res = yield call(AccumulationsAPI.getTotalAccumulations as any, {
    ...action.payload,
  });

  if (!res.error) {
    yield put(
      totalAccumulationsAction.setList({
        ...res.data,
      })
    );
  } else {
    yield put(
      totalAccumulationsAction.setList({
        total_count: 0,
        total_page: 0,
        results: [],
      })
    );
  }
}

export function* totalAccumulationsSaga() {
  yield all([
    takeLatest(totalAccumulationsAction.getList, getTotalAccumulations),
  ]);
}
