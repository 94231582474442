import {
  IGetWalletAllStreamsParams,
  IGetWalletDexTxsParams,
  IGetWalletExternalTxsParams,
  IGetWalletInternalTxsParams,
  IGetWalletTokenTradesParams,
  IGetWalletTokenTransfersParams,
  IGetWalletTradedTokensParams,
  IResolveEnsParams,
} from "../../@types/api";
import { BaseAPI } from "./BaseAPI";

export class WalletsAPI {
  static getTotalBalance = async (chain: string, address: string) => {
    return BaseAPI.callAPI({
      url: `/wallet/${address}/total_balance/`,
      params: {
        chain,
      },
    });
  };

  static getNumNfts = async (chain: string, address: string) => {
    return BaseAPI.callAPI({
      url: `/wallet/${address}/num_nfts/`,
      params: {
        chain,
      },
      needToken: false,
    });
  };

  static getNetWorth = async (chain: string, address: string) => {
    return BaseAPI.callAPI({
      url: `/wallet/${address}/balance/`,
      params: {
        chain,
      },
      needToken: false,
    });
  };

  static getRealizedPnl = async (chain: string, address: string) => {
    return BaseAPI.callAPI({
      url: `/wallet/${address}/realized_pnl/`,
      params: {
        chain,
      },
      needToken: false,
    });
  };

  static getEnsName = async (chain: string, address: string) => {
    return BaseAPI.callAPI({
      url: `/wallet/${address}/ens/`,
      params: {
        chain,
      },
      needToken: false,
    });
  };

  static getAge = async (chain: string, address: string) => {
    return BaseAPI.callAPI({
      url: `/wallet/${address}/age/`,
      params: {
        chain,
      },
      needToken: false,
    });
  };

  static getRanking = async (chain: string, address: string) => {
    return BaseAPI.callAPI({
      url: `/wallet/${address}/ranking/`,
      params: {
        chain,
      },
      needToken: false,
    });
  };

  static getLabel = async (chain: string, address: string) => {
    return BaseAPI.callAPI({
      url: `/wallet/${address}/label/`,
      params: {
        chain,
      },
      needToken: false,
    });
  };

  static getActivities = async (chain: string, address: string) => {
    return BaseAPI.callAPI({
      url: `/wallet/${address}/num_trades/`,
      params: {
        chain,
      },
      needToken: false,
    });
  };

  static getTopTokens = async (chain: string, address: string) => {
    return BaseAPI.callAPI({
      url: `wallet/${address}/top_tokens/`,
      params: {
        chain,
      },
    });
  };

  static getTokenDist = async (chain: string, address: string) => {
    return BaseAPI.callAPI({
      url: `/wallet/${address}/token_dist/`,
      params: {
        chain,
      },
      needToken: false,
    });
  };

  static getWalletDexTransaction = async ({
    chain,
    wallet_address,
    page = 1,
    page_size = 50,
    ordering = "",
    filter_by = null,
    filter_values = "",
  }: IGetWalletDexTxsParams) => {
    return BaseAPI.callAPI({
      url: `/wallet/${wallet_address}/transactions/dex/`,
      params: {
        chain,
        page,
        page_size,
        ordering,
        filter_by,
        filter_values,
      },
      needToken: false,
    });
  };

  static getWalletAllStreams = async ({
    chain,
    from_ts,
    wallet_address,
  }: IGetWalletAllStreamsParams) => {
    return BaseAPI.callAPI({
      url: `/wallet/${wallet_address}/transactions/all_stream/`,
      params: {
        chain,
        from_ts,
      },
    });
  };

  static getWalletTokenTransfers = async ({
    chain,
    page = 1,
    page_size = 25,
    total_count = false,
    wallet_address,
  }: IGetWalletTokenTransfersParams) => {
    return BaseAPI.callAPI({
      url: `/wallet/${wallet_address}/transactions/erc20/`,
      params: {
        chain,
        page,
        page_size,
        total_count,
      },
      needToken: false,
    });
  };

  static getExternalTransactions = async ({
    chain,
    page = 1,
    page_size = 25,
    total_count = false,
    wallet_address,
  }: IGetWalletExternalTxsParams) => {
    return BaseAPI.callAPI({
      url: `/wallet/${wallet_address}/transactions/external/`,
      params: {
        chain,
        page,
        page_size,
        total_count,
      },
      needToken: false,
    });
  };

  static getInternalTransactions = async ({
    chain,
    page = 1,
    page_size = 25,
    total_count = false,
    wallet_address,
  }: IGetWalletInternalTxsParams) => {
    return BaseAPI.callAPI({
      url: `/wallet/${wallet_address}/transactions/internal/`,
      params: {
        chain,
        page,
        page_size,
        total_count,
      },
      needToken: false,
    });
  };

  static getTradedTokens = async ({
    chain,
    period = "24h",
    wallet_address,
  }: IGetWalletTradedTokensParams) => {
    return BaseAPI.callAPI({
      url: `/wallet/${wallet_address}/traded_tokens/`,
      params: {
        chain,
        period,
      },
      needToken: false,
    });
  };

  static getTokenTrades = async ({
    chain,
    period = "24h",
    token_address,
    wallet_address,
  }: IGetWalletTokenTradesParams) => {
    return BaseAPI.callAPI({
      url: `/wallet/${wallet_address}/token_trades/`,
      params: {
        chain,
        period,
        token_address,
      },
      needToken: false,
    });
  };

  static getNetWorthHistory = async (chain: string, address: string) => {
    return BaseAPI.callAPI({
      url: `/wallet/${address}/balance_history/`,
      params: {
        chain,
      },
      needToken: false,
    });
  };

  static getCexUsageData = async (
    chain: string,
    period: string,
    wallet_address: string
  ) => {
    return BaseAPI.callAPI({
      url: `/wallet/${wallet_address}/cex_usage/`,
      params: {
        chain,
        period,
      },
      needToken: false,
    });
  };

  static resolveEns = async (data: IResolveEnsParams) => {
    return BaseAPI.callAPI({
      url: "/ens/resolve/",
      method: "post",
      data: data,
      needToken: false,
    });
  };
}
