import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { sidebarActions } from "../../redux/features/sidebarSlice";
import { sidebarStateSelector } from "../../redux/selectors/sidebarSelectors";
import { Chains, ChainSelectorItems } from "../../utils/constants";
import { AppRoutes } from "../../utils/routes";
import AllChainsIcon from "../Icons/AllChainsIcon";
import ArbitrumIcon from "../Icons/ArbitrumIcon";
import AvalancheIcon from "../Icons/AvalancheIcon";
import BaseIcon from "../Icons/BaseIcon";
import BinanceIcon from "../Icons/BinanceIcon";
import CronosIcon from "../Icons/CronosIcon";
import DropdownIcon from "../Icons/DropdownIcon";
import EthereumIcon from "../Icons/EthereumIcon";
import OptimismIcon from "../Icons/OptimismIcon";
import PolygonIcon from "../Icons/PolygonIcon";
import SolanaIcon from "../Icons/SolanaIcon";

import "./scss/ChainSelector.scss";

interface IChainData {
  label: string;
  value: string;
}

interface ISupportChainData {
  [key: string]: string[];
}

const ChainSelector = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getCorrectPath = () => {
    if (
      location.pathname.startsWith(
        AppRoutes.getWalletProfileRouteWithoutRefCode()
      )
    )
      return AppRoutes.walletIndex;
    if (
      location.pathname.startsWith(
        AppRoutes.getTokenGeniusRouteWithoutRefCode()
      )
    )
      return AppRoutes.tokenGeniusIndex;
    return location.pathname;
  };
  const path = getCorrectPath();

  const supportChains: ISupportChainData = {};
  supportChains[AppRoutes.smartDexStream] = [
    Chains.All,
    Chains.Ethereum,
    Chains.Arbitrum,
    Chains.Binance,
    Chains.Polygon,
    Chains.Optimism,
    Chains.Avalanche,
    Chains.Base,
    Chains.Cronos,
  ];
  supportChains[AppRoutes.whaleTrades] = [
    Chains.All,
    Chains.Ethereum,
    Chains.Arbitrum,
    Chains.Binance,
    Chains.Polygon,
    Chains.Optimism,
    Chains.Avalanche,
    Chains.Base,
    Chains.Cronos,
  ];
  supportChains[AppRoutes.tokenGeniusIndex] = [
    Chains.All,
    Chains.Ethereum,
    Chains.Arbitrum,
    Chains.Binance,
    Chains.Polygon,
    Chains.Optimism,
    Chains.Avalanche,
    Chains.Base,
    Chains.Cronos,
  ];
  supportChains[AppRoutes.walletIndex] = [
    Chains.Ethereum,
    Chains.Arbitrum,
    Chains.Binance,
    Chains.Polygon,
    Chains.Optimism,
    Chains.Avalanche,
    Chains.Base,
    Chains.Cronos,
  ];
  supportChains[AppRoutes.smartHoldings] = [
    Chains.Ethereum,
    Chains.Arbitrum,
    Chains.Binance,
    Chains.Polygon,
    Chains.Avalanche,
    Chains.Base,
    Chains.Cronos,
  ];
  supportChains[AppRoutes.newWalletAccumulations] = [
    Chains.Ethereum,
    Chains.Arbitrum,
    Chains.Binance,
    Chains.Polygon,
    Chains.Optimism,
    Chains.Avalanche,
    Chains.Base,
    Chains.Cronos,
  ];
  supportChains[AppRoutes.dexAccumulations] = [
    Chains.Ethereum,
    Chains.Arbitrum,
    Chains.Binance,
    Chains.Polygon,
    Chains.Optimism,
    Chains.Avalanche,
    Chains.Base,
    Chains.Cronos,
  ];
  supportChains[AppRoutes.cexNetTransactions] = [
    Chains.Ethereum,
    Chains.Arbitrum,
    Chains.Binance,
    Chains.Polygon,
    Chains.Optimism,
    Chains.Avalanche,
    Chains.Base,
    Chains.Cronos,
  ];
  supportChains[AppRoutes.onchainAI] = [Chains.Ethereum];
  supportChains[AppRoutes.perpGmxData] = [Chains.Ethereum];
  supportChains[AppRoutes.perpGmxTraderData] = [Chains.Ethereum];
  supportChains[AppRoutes.perpOpenTrades] = [Chains.Ethereum];

  const { chain } = useSelector(sidebarStateSelector);
  const [state, setState] = useState({
    isMenuOpen: false,
  });

  if (supportChains[path] && !supportChains[path].includes(chain))
    dispatch(sidebarActions.setCurrentChain(Chains.Ethereum));

  const handleChainSelect = (event: SelectChangeEvent) => {
    dispatch(sidebarActions.setCurrentChain(event.target.value));
    setState({
      ...state,
      isMenuOpen: false,
    });
    if (
      location.pathname.startsWith(
        AppRoutes.getTokenGeniusRouteWithoutRefCode()
      )
    )
      navigate(AppRoutes.getExactRoute(AppRoutes.home));
  };

  const handleClickSelector = (open = false) => {
    setState({
      ...state,
      isMenuOpen: open,
    });
  };

  const renderSelector = () => {
    return ChainSelectorItems.map((chain: IChainData) => {
      return (
        <MenuItem
          key={chain.value}
          value={chain.value}
          disabled={
            supportChains[path] && !supportChains[path].includes(chain.value)
          }
        >
          <div className="chain-icon-container">
            {chain.value === Chains.All ? <AllChainsIcon /> : null}
            {chain.value === Chains.Ethereum ? <EthereumIcon /> : null}
            {chain.value === Chains.Arbitrum ? <ArbitrumIcon /> : null}
            {chain.value === Chains.Binance ? <BinanceIcon /> : null}
            {chain.value === Chains.Polygon ? <PolygonIcon /> : null}
            {chain.value === Chains.Avalanche ? <AvalancheIcon /> : null}
            {chain.value === Chains.Base ? <BaseIcon /> : null}
            {chain.value === Chains.Optimism ? <OptimismIcon /> : null}
            {chain.value === Chains.Cronos ? <CronosIcon /> : null}
            {chain.value === Chains.Solana ? <SolanaIcon /> : null}
          </div>
          <a className="chain-label">{chain.label}</a>
        </MenuItem>
      );
    });
  };

  return (
    <div className="chain-selector">
      <Select
        displayEmpty
        className="select"
        open={state.isMenuOpen}
        value={chain}
        inputProps={{ "aria-label": "Without label" }}
        endAdornment={
          <div onClick={() => handleClickSelector(true)}>
            <DropdownIcon />
          </div>
        }
        onChange={handleChainSelect}
        MenuProps={{
          disablePortal: true,
        }}
        onClose={() => handleClickSelector(false)}
        onOpen={() => handleClickSelector(true)}
      >
        {renderSelector()}
      </Select>
    </div>
  );
};

export default ChainSelector;
