import { Dialog } from "@mui/material";
import React, { useState } from "react";
import YouTube from "react-youtube";
import { Swiper, SwiperSlide } from "swiper/react";
import { TutorialVideoID } from "../../utils/constants";
import { LocalStorages } from "../../utils/localStorages";

import "./scss/TutorialVideo.scss";

const TutorialDialog = () => {
  const [open, setOpen] = useState(true);

  const opts = {
    playerVars: {
      autoplay: 1, // 1 means automatic play, 0 none autoplay
    },
  };

  const handleClose = (event: object, reason: string) => {
    if (reason === "backdropClick") return;
  };

  return (
    <Dialog
      className="tutorial-dialog"
      open={open}
      onClose={handleClose}
      maxWidth={false}
    >
      <div className="dialog-header">
        Welcome 🙌
        <div className="overview">A Quick Overview of Alphanomics</div>
      </div>
      <div className="dialog-content">
        <Swiper autoPlay>
          <SwiperSlide>
            <YouTube
              videoId={TutorialVideoID}
              className="tutorial-video"
              opts={opts}
            />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="dialog-footer">
        <button
          className="button-primary"
          onClick={() => {
            setOpen(false);
            LocalStorages.hideTutorial();
          }}
        >
          Okay, Got it
        </button>
      </div>
    </Dialog>
  );
};

export default TutorialDialog;
